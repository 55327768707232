// REMIX HMR BEGIN
import * as __hmr__ from "remix:hmr";
if (import.meta) {
import.meta.hot = __hmr__.createHotContext(
//@ts-expect-error
"app/utils/userActivity.ts"
);
import.meta.hot.lastModified = "1738642866264.8428";
}
// REMIX HMR END

import type { JsonBody } from 'aws-sdk/clients/wafv2';
import ApiService from '../services/ApiService';

export const logActivity = async(activityMessage: string, metaData? : JsonBody) => {

    let userActivity ;
    if(metaData){
        userActivity = {
            activity: activityMessage,
            metaData: metaData? metaData : null,
        };
    }else{
        userActivity = {
            activity: activityMessage,
        };
    }

      try {
        await ApiService.post(`/api/user/activity`, userActivity);
      } catch (error) {
        console.error("Error posting user activity", error);
      }
    
    return null;
}
